export function ChangeShapeBorderColor(color, shape) {
  if (shape.type === 'text') {
    shape.config.fill = color
  } else {
    shape.config.stroke = color
  }
  if (shape.type === 'arrow') {
    shape.config.pointerColor = color
    shape.config.fill = color
  }
}

export function ChangeShapeBorderType(borderType, shape) {
  let dash = []
  switch (borderType) {
    case 'dashed':
      dash = [10, 5]
      break
    case 'point':
      dash = [3, 3]
      break
    default:
      break
  }
  if (shape) {
    shape.config.dash = dash
  }
  return dash
}
