export function DrawArrowPreview(arrowPoints, pointerPosition, lastPointPosition, borderColor, dash) {
  const coordinates = ArrowCoordinates(pointerPosition, lastPointPosition)
  const arrowPreview = {
    config: {
      points: [...arrowPoints, coordinates.x, coordinates.y],
      pointerLength: 10,
      pointerWidth: 10,
      pointerColor: borderColor,
      fill: borderColor,
      stroke: borderColor,
      strokeWidth: 1,
      draggable: false,
      strokeScaleEnabled: false,
      dash: dash
    }
  }

  return arrowPreview
}

function PointsDifference(p1, p2) {
  if(p1 > p2) {
    return p1 - p2
  } else {
    return p2 - p1
  }
}

export function ArrowCoordinates(pointerPosition, lastPointPosition) {
  let x = 0
  let y = 0
  const xPointsDifference = PointsDifference(pointerPosition.x, lastPointPosition.x)
  const yPointsDifference = PointsDifference(pointerPosition.y, lastPointPosition.y)
  if(xPointsDifference <= yPointsDifference) {
    x = lastPointPosition.x
    y = pointerPosition.y
  } else {
    x = pointerPosition.x
    y = lastPointPosition.y
  }
  return { x: x, y: y }
}