export function GetTempCanvasToExport(width, height, stageNode) {

  const tempCanvas = document.createElement('canvas')
  tempCanvas.width = width
  tempCanvas.height = height
  const ctx = tempCanvas.getContext('2d')
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 0, width, height)
  ctx.drawImage(stageNode.toCanvas(), 0, 0)

  return tempCanvas
}
