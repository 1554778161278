<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import DrawToolbar from './DrawToolbar.vue'
import TextInputModal from './TextInputModal.vue'
import { SaveDraw } from '../composable/saveDraw.js'
import { DrawArrow, DrawBasicShape, DrawText } from '../composable/drawBasicShape.js'
import { GetTempCanvasToExport } from '../composable/getTempCanvasToExport.js'
import { HandleTransformEnd } from '../composable/handleTransformEnd.js'
import { DrawArrowPreview, ArrowCoordinates } from '../composable/drawArrowPreview.js'
import { OrderShapes } from '../composable/orderShapes.js'
import { ChangeShapeBorderColor, ChangeShapeBorderType } from '../composable/changeShape.js'

const configKonva = ref({
  width: 500,
  height: 800
})

const props = defineProps({
  drawData: Object
})

const arrowPoints = ref([])
const border = ref('continuous')
const borderColor = ref('black')
const dash = ref([])
const draw = ref(null)
const isNameOfDraw = ref(false)
const lastArrowPointPosition = ref(null)
const history = ref([])
const historyIndex = ref(0)
const newTextPointerPosition = ref({ x: 0, y: 0 })
const selectedShapeName = ref('')
const shape = ref('')
const shapeNumberForName = ref(1)
const shapes = ref([])
const shapeToCopy = ref(null)
const stage = ref(null)
const tempArrow = ref(null)
const textToEdit = ref(null)
const transformer = ref(null)
const visibleInput = ref(false)

let autoSaveInterval = null

const autoSave = async () => {
  const previewImage = await generatePreviewImage()
  const formData = new FormData()
  const name = draw.value ? draw.value.name : 'untitled'
  formData.append('draw[name]', name)
  formData.append('draw[shapes]', JSON.stringify(shapes.value))
  formData.append('draw[preview_image]', previewImage)
  formData.append('draw[index_shape]', shapeNumberForName.value)
  const method = draw.value ? 'PATCH' : 'POST'
  const url = draw.value ? `/draws/${draw.value.id}` : '/draws'
  fetch(url, {
    method: method,
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    body: formData
  })
  .then(response => response.json())
  .then(data => {
    draw.value = data
  })
}

const bringForward = () => {
  if (selectedShapeName.value) {
    const shape = findShape(selectedShapeName.value)
    const index = shapes.value.indexOf(shape)
    if (index !== shapes.value.length - 1) {
      shapes.value.splice(index, 1)
      shapes.value.splice(index + 1, 0, shape)
    }
  }
}

const sendBackward = () => {
  if (selectedShapeName.value) {
    const shape = findShape(selectedShapeName.value)
    const index = shapes.value.indexOf(shape)
    if (index !== 0) {
      shapes.value.splice(index, 1)
      shapes.value.splice(index - 1, 0, shape)
    }
  }
}

const changeBorder = (borderType) => {
  border.value = borderType
  let shape = undefined
  if(selectedShapeName.value) {
    shape = findShape(selectedShapeName.value)
  }
  dash.value = ChangeShapeBorderType(borderType, shape)
}

const changeColor = (color) => {
  borderColor.value = color
  if(selectedShapeName.value) {
    const shape = findShape(selectedShapeName.value)
    ChangeShapeBorderColor(color, shape)
  }
}

const changeShapePosition = (event) => {
  const shape = findShape(event.target.name())
  if(shape.config.x != event.target.x() || shape.config.y != event.target.y()) {
    shape.config.x = event.target.x()
    shape.config.y = event.target.y()
    saveHistory()
  }
}

const closeText = () => {
  visibleInput.value = false
  textToEdit.value = null
}

const deleteShape = () => {
  if (selectedShapeName.value) {
    shapes.value = shapes.value.filter(shape => shape.config.name !== selectedShapeName.value)
    saveHistory()
    selectedShapeName.value = ''
    updateTransformer()
  }
}

const downloadImage = () => {
  selectedShapeName.value = ''
  updateTransformer()
  const stageNode = stage.value.getNode()
  const tempCanvas = GetTempCanvasToExport(stageNode.width(), stageNode.height(), stageNode)
  const dataURL = tempCanvas.toDataURL('image/jpeg');

  var link = document.createElement('a');
  if (draw.value) {
    link.download = `${draw.value.name}.jpg`;
  } else {
    link.download = 'image.jpg';
  }
  link.href = dataURL;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const drawArrow = () => {
  const newArrow = DrawArrow(arrowPoints.value,
                             borderColor.value,
                             dash.value,
                             shapeNumberForName.value)
  shapes.value.push(newArrow)
  saveHistory()
  tempArrow.value = null
  lastArrowPointPosition.value = null
  arrowPoints.value = []
  shapeNumberForName.value++
}

const drawArrowPreview = () => {
  if (shape.value !== 'arrow') {
    return
  }
  if (arrowPoints.value.length !== 0) {
    const stageNode = stage.value.getNode()
    const pointerPosition = stageNode.getPointerPosition()
    const newArrow = DrawArrowPreview(arrowPoints.value,
                                      pointerPosition,
                                      lastArrowPointPosition.value,
                                      borderColor.value,
                                      dash.value)
    tempArrow.value = newArrow
  }
}

const drawCopy = () => {
  let newShape = JSON.parse(JSON.stringify(shapeToCopy.value))
  const newName = `${newShape.config.name.split('-')[0]}-${shapeNumberForName.value}`
  newShape.config.name = newName
  shapes.value.push(newShape)
  saveHistory()
  shapeNumberForName.value++
}

const drawShape = () => {
  const stageNode = stage.value.getNode()
  const pointerPosition = stageNode.getPointerPosition()
  switch (shape.value) {
    case 'arrow':
      if (arrowPoints.value.length === 0) {
        arrowPoints.value.push(pointerPosition.x, pointerPosition.y)
        lastArrowPointPosition.value = pointerPosition
      } else if (lastArrowPointPosition.value.x === pointerPosition.x && lastArrowPointPosition.value.y === pointerPosition.y) {
        drawArrow()
      } else {
        const coordinates = ArrowCoordinates(pointerPosition,
                                             lastArrowPointPosition.value)
        arrowPoints.value.push(coordinates.x, coordinates.y)
        lastArrowPointPosition.value = coordinates
      }
      break
    case 'text':
      drawText(pointerPosition)
      break
    case '':
    case 'eraser':
      break
    default:
      const newShape = DrawBasicShape(shape.value,
                                      pointerPosition,
                                      borderColor.value,
                                      dash.value,
                                      shapeNumberForName.value)
      shapes.value.push(newShape)
      saveHistory()
      OrderShapes(shapes.value)
      shapeNumberForName.value++
      break
  }
}

const drawText = (pointerPosition) => {
  newTextPointerPosition.value = pointerPosition
  visibleInput.value = true
}

const editText = (name) => {
  textToEdit.value = findShape(name)
  visibleInput.value = true
}

const findShape = (name) => {
  return shapes.value.find(shape => shape.config.name === name)
}

const generatePreviewImage = () => {
  selectedShapeName.value = ''
  updateTransformer()
  return new Promise((resolve) => {
    const stageNode = stage.value.getNode()
    const tempCanvas = GetTempCanvasToExport(600, 600, stageNode)
    tempCanvas.toBlob((blob) => {
      resolve(blob)
    }, 'image/jpeg')
  })
}

const handleKeyDown = (event) => {
  if (event.key === 'Delete') {
    deleteShape(event)
  } else if (event.key === 'Escape') {
    selectedShapeName.value = ''
    if(tempArrow.value) {
      tempArrow.value = null
      arrowPoints.value = []
      lastArrowPointPosition.value = null
    }
    updateTransformer()
  } else if (event.ctrlKey && event.key === 'z') {
    undoLastShape()
  } else if (event.ctrlKey && event.key === 'c') {
    shapeToCopy.value = findShape(selectedShapeName.value)
    updateTransformer()
  } else if (event.ctrlKey && event.key === 'v' && shapeToCopy.value) {
    drawCopy()
    updateTransformer()
  } else if (event.key === 'v') {
    shape.value = ''
  } else if (event.key === 'Enter' && shape.value === 'arrow' && arrowPoints.value.length > 0) {
    drawArrow()
  }
}

const handleResize = () => {
  const canvasContainer = document.getElementById('canvas-container')
  configKonva.value.width = canvasContainer.offsetWidth
  configKonva.value.height = canvasContainer.offsetHeight
}

const handleStageMouseDown = (event) => {
  const target = event.target
  if (shape.value === 'eraser') {
    selectedShapeName.value = target.name()
    deleteShape()
    return
  }

  if (target === stage.value.getStage()) {
    selectedShapeName.value = ''
    drawShape(event)
    updateTransformer()
    return
  }

  const clickedOnTransformer = target.getParent().className === 'Transformer'
  if (clickedOnTransformer) {
    return
  }

  const shapeSelected = findShape(target.name())
  if (shapeSelected) {
    selectedShapeName.value = shapeSelected.config.name
  } else {
    selectedShapeName.value = ''
  }
  updateTransformer()
}

const handleTransformEnd = (event) => {
  const shape = findShape(event.target.name())
  const node = event.target
  HandleTransformEnd(shape, node)
  saveHistory()
}

const saveHistory = (firstStep = false) => {
  const currentState = JSON.parse(JSON.stringify(shapes.value))
  if(!firstStep) {
    historyIndex.value++
  }
  history.value[historyIndex.value] = [...currentState]
  history.value = history.value.slice(0, historyIndex.value + 1)
}

const redoLastShape = () => {
  if (historyIndex.value === history.value.length - 1) {
    return
  }
  historyIndex.value++
  shapes.value = [...history.value[historyIndex.value]]
  updateTransformer()
}

const saveImage = () => {
  isNameOfDraw.value = true
  visibleInput.value = true
}

const selectShape = (selectedShape) => {
  if(tempArrow.value) {
    tempArrow.value = null
    arrowPoints.value = []
    lastArrowPointPosition.value = null
  }
  shape.value = selectedShape
}

const submitImage = async (name) => {
  isNameOfDraw.value = false
  visibleInput.value = false
  const previewImage = await generatePreviewImage()
  const formData = new FormData()
  formData.append('draw[name]', name)
  formData.append('draw[shapes]', JSON.stringify(shapes.value))
  formData.append('draw[preview_image]', previewImage)
  const method = draw.value ? 'PATCH' : 'POST'
  SaveDraw(method, formData, draw.value?.id)
}

const submitText = (text) => {
  const newText = DrawText(newTextPointerPosition.value,
                           text,
                           shapeNumberForName.value,
                           borderColor.value)
  shapes.value.push(newText)
  saveHistory()
  shapeNumberForName.value++
  visibleInput.value = false
}

const title = () => {
  if (draw.value)
    return draw.value.name
  return 'Nuovo disegno'
}

const undoLastShape = () => {
  if (historyIndex.value === 0) {
    return
  }
  historyIndex.value--
  shapes.value = [...history.value[historyIndex.value]]
  updateTransformer()
}

const updateText = (text) => {
  textToEdit.value.config.text = text
  visibleInput.value = false
  textToEdit.value = null
}

const updateTransformer = () => {
  if (transformer.value) {
    const transformerNode = transformer.value.getNode()
    const stage = transformerNode.getStage()
    const selectedNode = stage.findOne('.' + selectedShapeName.value)
    if (selectedNode === transformerNode.node()) {
      return
    }
    if (selectedNode) {
      transformerNode.nodes([selectedNode])
    } else {
      transformerNode.nodes([])
    }
  }
}

onMounted(() => {
  handleResize()
  if(props.drawData) {
    shapes.value = JSON.parse(props.drawData.shapes)
    draw.value = props.drawData
    shapeNumberForName.value = props.drawData.index_shape
  }
  saveHistory(true)
  window.addEventListener('resize', handleResize)
  window.addEventListener('keydown', handleKeyDown)
  autoSaveInterval = setInterval(autoSave, 30000)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
  window.removeEventListener('keydown', handleKeyDown)
  clearInterval(autoSaveInterval)
})
</script>

<template>
  <h3 class="text-light">{{title()}}</h3>
  <div id="canvas-container">
    <DrawToolbar
      @select-shape="selectShape"
      @select-border="changeBorder"
      @select-color="changeColor"
      @undo-last-action="undoLastShape"
      @redo-last-action="redoLastShape"
      @download-image="downloadImage"
      @save-image="saveImage"
      @bring-forward="bringForward"
      @send-backward="sendBackward"
      :actual-shape="shape"
      :actual-border="border"
      :actual-color="borderColor"
    />
    <v-stage
      ref="stage"
      :config="configKonva"
      class="konva-stage"
      @mousedown="handleStageMouseDown"
      @touchstart="handleStageMouseDown"
      @mousemove="drawArrowPreview"
    >
      <v-layer>
        <div v-for="(shape, index) in shapes" :key="index">
          <v-circle
            v-if="shape.type === 'circle'"
            :config="shape.config"
            @transformend="handleTransformEnd"
            @mouseup="changeShapePosition"
          />
          <v-rect
            v-if="shape.type === 'rectangle'"
            :config="shape.config"
            @transformend="handleTransformEnd"
            @mouseup="changeShapePosition"
          />
          <v-regular-polygon
            v-if="shape.type === 'triangle'"
            :config="shape.config"
            @transformend="handleTransformEnd"
            @mouseup="changeShapePosition"
          />
          <v-arrow
            v-if="shape.type === 'arrow'"
            :config="shape.config"
            @transformend="handleTransformEnd"
            @mouseup="changeShapePosition"
          />
          <v-text
            v-if="shape.type === 'text'"
            :config="shape.config"
            @transformend="handleTransformEnd"
            @dblclick="editText(shape.config.name)"
            @mouseup="changeShapePosition"
          />
        </div>
        <v-transformer ref="transformer" />
        <v-arrow
          v-if="tempArrow"
          :config="tempArrow.config"
        />
      </v-layer>
    </v-stage>
    <TextInputModal
      v-if="visibleInput"
      @submit="(text) => { submitText(text) }"
      @update="(text) => { updateText(text) }"
      @cancel="closeText()"
      @submit-image="(text) => { submitImage(text) }"
      :text-to-edit="textToEdit"
      :is-name-of-draw="isNameOfDraw"
      :actual-name="draw ? draw.name : ''"
    />
  </div>
</template>

<style scoped>
#canvas-container {
  background-color: #FFFFFF
}
.text-input-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
