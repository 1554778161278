<script setup>
import { ref } from 'vue'

const props = defineProps({
  actualShape: String,
  actualBorder: String,
  actualColor: String
})
const continuousImage = '/draw-toolbar/continuous.svg'
const dashedImage = '/draw-toolbar/dashed.svg'
const pointImage = '/draw-toolbar/point.svg'
const advancedOptions = ref(false)

const classShapeSelected = (shape) => {
  return shape === props.actualShape ? 'button-selector-selected' : 'button-selector'
}

const classBorderSelected = (border) => {
  return border === props.actualBorder ? 'button-selector-selected' : 'button-selector'
}

const selectedColor = () => {
  return `/draw-toolbar/${props.actualColor}.svg`
}

const marginIfAdvancedVisible = () => {
  return advancedOptions.value ? 'mb-2' : ''
}

</script>

<template>
  <div class="toolbar-container">
    <div :class="marginIfAdvancedVisible()">
      <button :class="classShapeSelected('')" @click="$emit('selectShape', '')">
        <i class="bi bi-cursor" />
      </button>
      <button
        :class="classShapeSelected('detail')"
        @click="$emit('selectShape', 'detail')"
        title="Dettaglio (es: Fornitura elettrica, ufficio, locale caldaie)"
      >
        <i class="bi bi-1-square" />
      </button>
      <button
        :class="classShapeSelected('area')"
        @click="$emit('selectShape', 'area')"
        title="Edificio"
      >
        <i class="bi bi-2-square" />
      </button>
      <button
        :class="classShapeSelected('company')"
        @click="$emit('selectShape', 'company')"
        title="Azienda"
      >
        <i class="bi bi-3-square" />
      </button>
      <button
        :class="classShapeSelected('pod')"
        @click="$emit('selectShape', 'pod')"
        title="POD"
      >
        <i class="bi bi-rulers" />
      </button>
      <button :class="classShapeSelected('arrow')" @click="$emit('selectShape', 'arrow')">
        <i class="bi bi-arrow-up-right" />
      </button>
      <button :class="classShapeSelected('text')" @click="$emit('selectShape', 'text')">
        <i class="bi bi-fonts" />
      </button>
      <button
        class="button-selector"
        @click="$emit('bringForward')"
        title="Porta in primo piano"
      >
        <i class="bi bi-box-arrow-up" />
      </button>
      <button
        class="button-selector"
        @click="$emit('sendBackward')"
        title="Porta in secondo piano"
      >
        <i class="bi bi-box-arrow-down" />
      </button>
      <button
        class="button-selector"
        title="Indietro"
        @click="$emit('undoLastAction')">
        <i class="bi bi-arrow-counterclockwise" />
      </button>
      <button
        class="button-selector"
        title="Avanti"
        @click="$emit('redoLastAction')">
        <i class="bi bi-arrow-clockwise" />
      </button>
      <button :class="classShapeSelected('eraser')" @click="$emit('selectShape', 'eraser')">
        <i class="bi bi-eraser" />
      </button>
      <button class="button-selector" @click="$emit('saveImage')">
        <i class="bi bi-floppy2" />
      </button>
      <button class="button-selector" @click="$emit('downloadImage')">
        <i class="bi bi-download" />
      </button>
      <button class="button-selector" @click="advancedOptions = !advancedOptions">
        <i class="bi bi-gear" />
      </button>
    </div>
    <div v-if="advancedOptions">
      <span class="tool-container me-1">
        <button :class="classShapeSelected('circle')" @click="$emit('selectShape', 'circle')">
          <i class="bi bi-circle" />
        </button>
        <button :class="classShapeSelected('rectangle')" @click="$emit('selectShape', 'rectangle')">
          <i class="bi bi-square" />
        </button>
        <button :class="classShapeSelected('triangle')" @click="$emit('selectShape', 'triangle')">
          <i class="bi bi-triangle" />
        </button>
      </span>
      <span class="tool-container me-1">
        <button :class="classBorderSelected('continuous')" @click="$emit('selectBorder', 'continuous')">
          <img :src=continuousImage class='button-image'/>
        </button>
        <button :class="classBorderSelected('dashed')" @click="$emit('selectBorder', 'dashed')">
          <img :src=dashedImage class='button-image'/>
        </button>
        <button :class="classBorderSelected('point')" @click="$emit('selectBorder', 'point')">
          <img :src=pointImage class='button-image'/>
        </button>
        <img :src=selectedColor() class='button-image ms-2 me-2'/>
        <select
          :selected=actualColor
          @change="$emit('selectColor', $event.target.value)"
          class="me-1"
        >
          <option value="black">Nero</option>
          <option value="red">Rosso</option>
          <option value="yellow">Giallo</option>
          <option value="green">Verde</option>
          <option value="blue">Blu</option>
          <option value="white">Bianco</option>
        </select>
      </span>
    </div>
  </div>
</template>

<style scoped>
.toolbar-container {
  background-color: #EEEEEE;
  border-bottom: 1px solid #000000;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  min-height: 50px;
  padding: 10px;
}
.button-selector {
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  margin-right: 5px;
  padding: 5px 10px;
}
.button-selector-selected {
  border: 1px solid #AAAAAA;
  border-radius: 4px;
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #888888;
}
.button-image {
  width: 18px;
  height: 16px
}
.tool-container {
  border-right: 1px solid #888888;
}
</style>
