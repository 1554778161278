export function DrawBasicShape(shape, pointerPosition, borderColor, dash, shapeNumberForName) {
  switch (shape) {
    case 'detail':
    case 'area':
    case 'company':
    case 'pod':
      return drawFixed(pointerPosition, shapeNumberForName, shape)
    case 'circle':
      return drawCircle(pointerPosition, borderColor, dash, shapeNumberForName)
    case 'rectangle':
      return drawRectangle(pointerPosition, borderColor, dash, shapeNumberForName)
    case 'triangle':
      return drawTriangle(pointerPosition, borderColor, dash, shapeNumberForName)
    default:
      break
  }
}

function drawFixed(pointerPosition, shapeNumberForName, shape) {
  let fillColor = ''
  let strokeColor = ''
  switch (shape) {
    case 'detail':
      fillColor = '#5080b9'
      strokeColor = '#45617e'
      break
    case 'area':
      fillColor = '#96b2d7'
      strokeColor = '#455e7b'
      break
    case 'company':
      fillColor = '#dce6f2'
      strokeColor = '#0a1d45'
      break
    case 'pod':
      fillColor = '#ef0706'
      strokeColor = '#b42730'
    default:
      break
  }
  return {
    config: {
      x: pointerPosition.x,
      y: pointerPosition.y,
      width: 60,
      height: 60,
      fill: fillColor,
      stroke: strokeColor,
      strokeWidth: 2,
      draggable: true,
      strokeScaleEnabled: false,
      name: `${shape}-${shapeNumberForName}`
    },
    type: 'rectangle'
  }
}

function drawCircle(pointerPosition, borderColor, dash, shapeNumberForName) {
  const newCircle = {
    config: {
      x: pointerPosition.x,
      y: pointerPosition.y,
      radius: 30,
      fill: "transparent",
      stroke: borderColor,
      strokeWidth: 1,
      draggable: true,
      strokeScaleEnabled: false,
      dash: dash,
      name: `circle-${shapeNumberForName}`
    },
    type: 'circle'
  }
  return newCircle
}

function drawRectangle(pointerPosition, borderColor, dash, shapeNumberForName) {
  const newRectangle = {
    config: {
      x: pointerPosition.x - 30,
      y: pointerPosition.y - 30,
      width: 60,
      height: 60,
      fill: "transparent",
      stroke: borderColor,
      strokeWidth: 1,
      draggable: true,
      strokeScaleEnabled: false,
      dash: dash,
      name: `rectangle-${shapeNumberForName}`
    },
    type: 'rectangle'
  }
  return newRectangle
}

function drawTriangle(pointerPosition, borderColor, dash, shapeNumberForName) {
  const newTriangle = {
    config: {
      x: pointerPosition.x,
      y: pointerPosition.y,
      fill: 'transparent',
      sides: 3,
      radius: 80,
      stroke: borderColor,
      strokeWidth: 1,
      draggable: true,
      strokeScaleEnabled: false,
      dash: dash,
      name: `triangle-${shapeNumberForName}`
    },
    type: 'triangle'
  }
  return newTriangle
}

export function DrawText(pointerPosition, text, shapeNumberForName, color) {
  const newText = {
    config: {
      x: pointerPosition.x,
      y: pointerPosition.y,
      text: text,
      fontSize: 20,
      fontFamily: 'Arial',
      fill: color,
      draggable: true,
      name: `text-${shapeNumberForName}`
    },
    type: 'text'
  }
  return newText
}

export function DrawArrow(arrowPoints, borderColor, dash, shapeNumberForName) {
  return {
    config: {
      points: arrowPoints,
      pointerLength: 10,
      pointerWidth: 10,
      pointerColor: borderColor,
      fill: borderColor,
      stroke: borderColor,
      strokeWidth: 1,
      draggable: true,
      strokeScaleEnabled: false,
      dash: dash,
      name: `arrow-${shapeNumberForName}`
    },
    type: 'arrow'
  }
}
